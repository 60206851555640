/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'simple-keyboard/build/css/index.css';

@import 'theme/variables';

// Toolbars
.main-toolbar {
  height: 48px;
  padding: 2px 4px !important;

  ion-buttons {
    &,
    ion-button {
      min-height: unset;
      min-width: unset;
    }
  }

  .btn-back {
    --color: var(--ion-text-color);
    ion-icon {
      padding-top: 2px;
      width: 24px;
      height: 24px;
    }
  }
  .ss-logo {
    font-size: 20px;
    font-family: 'Vintage Dream', sans-serif;
    padding-top: 6px;
  }
}

// Tabs
ion-tab-bar,
ion-tab-button {
  border: none;
  //--background: var(--ion-tab-bar-background);
}

//Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  &.page-title {
    font-weight: 800;
    padding: 12px 0;
  }
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

.section-title-container, .subsection-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ion-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    ion-label {
      text-decoration: underline;
      text-underline-offset: 10%;
    }
  }
  ion-icon {
    font-size: 24px;
  }
}
.section-title-container {
  margin: 12px 0 6px 0;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
  }
}

.subsection-title-container, .no-flex-container {
  margin: 8px 0 4px 0;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }
}

.sunny-disclaimer {
  font-size: 8px;
  line-height: 0.5;
}

.bold {
  font-weight: 700;
}

.profile-header {
  height: 64px;
  display: flex;
  gap: 12px;
  align-items: center;
  ion-img {
    height: 60px;
    width: 60px;
    flex-shrink: 0;
  }
  ion-img::part(image) {
    border-radius: 15%;
  }
  ion-label {
    font-size: 12px;
  }
}

.profile-buttons {
  padding: 0;
  ion-row {
    gap: 8px;
    justify-content: center;
    flex-wrap: nowrap;
    ion-col {
      margin: 5px 0;
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }
    ion-button {
      flex: 1;
      max-width: 100%;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      font-size: 10px;
      --padding-start: 14px;
      --padding-end: 14px;
      border-radius: 8px;
      width: 100%;
      font-size: 12px;
      &.btn-follow {
        ion-icon {
          color: var(--ion-color-danger-contrast);
        }
      }
      &.btn-black-border {
        --color: var(--ion-text-color);
        --border-color: var(--ion-text-color);
        --border-style: solid;
        --border-width: 1.5px;
      }
    }
  }
}

.donation-total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  h3 {
    padding-top: 10px;
    line-height: 0.3;
    font-size: 20px;
  }
}

// Images & loading progress

ion-img.xsmall {
  height: 24px;
  width: 24px;
}
ion-img.small {
  height: 35px;
  width: 35px;
}
ion-img.large {
  height: 125px;
  width: 125px;
}

.image-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.image-wrapper ion-img,
.image-wrapper ion-skeleton-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-wrapper ion-img::part(image),
.image-wrapper ion-skeleton-text {
  border-radius: 15%;
}

.image-wrapper.round ion-img::part(image),
.image-wrapper.round ion-skeleton-text {
  border-radius: 50%;
}

.image-wrapper.square ion-img::part(image),
.image-wrapper.square ion-skeleton-text {
  border-radius: 0%;
}

.image-wrapper.xsmall {
  width: 24px;
  height: 24px;
  padding-top: 0;
}

.image-wrapper.small {
  width: 35px;
  height: 35px;
  padding-top: 0;
}

.image-wrapper.medium {
  width: 60px;
  height: 60px;
  padding-top: 0;
}

.image-wrapper.large {
  width: 125px;
  height: 125px;
  padding-top: 0;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

// Content
ion-content {
  --padding-top: 48px;
  --padding-bottom: 12px;
  --padding-start: 0;
  --padding-end: 0;
  &::-webkit-scrollbar {
    display: none;
  }
}

body.banner-visible ion-content.banner-page {
  --padding-top: 104px;
}

ion-modal ion-content {
  --padding-top: 48px !important;
}

// .modal-content {
//   --padding-top: 0px;
// }

.text-section {
  padding: 0 12px;
}

ion-refresher {
  margin-top: 44px;
}

// Search Bar
ion-searchbar {
  border: rgba(var(--ion-text-color-rgb), 0.5) 1px solid;
  border-radius: 4px;
  --background: var(--ion-background-color) !important;
  --inner-padding-start: 12px;
  &,
  div.searchbar-input-container {
    min-height: unset !important;
    height: 40px !important;
  }
}

.error-message {
  //color: #D32F2F;
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.error-message::before {
  content: "⚠ ";
  font-size: 16px;
  margin-right: 5px;
}

ion-select {
  --background: var(--ion-background-color) !important;
}

ion-thumbnail.small {
  --size: 30px;
}

ion-icon {
  color: var(--ion-text-color);
}

ion-item {
  --inner-padding-end: 0;
  --padding-start: 0;
  --background: var(--ion-background-color);
}

.line {
  background-color: #d9d9d954;
  width: 100vw;
  height: 5px;
  margin: 16px 0;
  position: relative;
}

.pad-item {
  --padding-start: 12px;
  --inner-padding-end: 12px;
}

.show-all-link {
  display: flex;
  justify-content: end;
  text-decoration: underline;
}

// Swiper
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #ffffff);
  --swiper-pagination-bullet-size: 14px;
  --swiper-pagination-bullet-width: 14px;
  --swiper-pagination-bullet-height: 14px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

// Responsive
// html {
//   font-size: 100%;

//   @media (max-width: 289px) {
//     font-size: 55%;
//   }

//   @media (min-width: 290px) {
//     font-size: 60%;
//   }

//   @media (min-width: 300px) {
//     font-size: 65%;
//   }

//   @media (min-width: 320px) {
//     font-size: 70%;
//   }

//   @media (min-width: 325px) {
//     font-size: 75%;
//   }

//   @media (min-width: 326px) {
//     font-size: 80%;
//   }

//   @media (min-width: 330px) {
//     font-size: 85%;
//   }

//   @media (min-width: 360px) and (max-height: 815px) {
//     font-size: 90%;

//     .logo {
//       max-width: 100%;
//     }
//   }

//   @media (min-width: 360px) and (max-height: 775px) {
//     font-size: 85%;

//     .logo {
//       max-width: 70%;
//     }
//   }

//   @media (min-width: 360px) and (max-height: 712px) {
//     font-size: 75%;

//     .logo {
//       max-width: 55%;
//     }
//   }

//   @media (min-width: 411px) and (max-height: 731px) {
//     font-size: 80%;

//     .logo {
//       max-width: 55%;
//     }
//   }

//   @media (min-width: 500px) {
//     font-size: 110%;
//   }

//   @media (min-width: 720px) {
//     font-size: 120%;
//   }

//   @media (min-width: 1000px) {
//     font-size: 130%;
//   }
// }

// html.ios {
//   @media (max-width: 359px) and (min-width: 320px) {
//     font-size: 59%;
//   }
// }

// Fonts
@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-VariableFont_slnt,wght.ttf');
}

@font-face {
  font-family: 'Losta Masta Medium';
  src: url('assets/fonts/LostaMasta-Medium.ttf');
}

@font-face {
  font-family: 'Vintage Dream';
  src: url('assets/fonts/Vintage Dreams_Regular_New Update.otf');
}

// Ion-tab animations
ion-router-outlet > .ion-page {
  animation: fadeIn 0.3s 1 forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Giving goal modal
.giving-goal-modal {
  --height: auto;
  --background: var(--ion-background-color);
  --width: 99%;
  --border-radius: 8px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

// botom sheet modal
.bottom-sheet-modal {
  --height: 30%;
  --width: 100%;
  --border-radius: 16px 16px 0 0;
  --box-shadow: none;

  &::part(backdrop) {
    z-index: 1000;
  }

  &::part(content) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    border-radius: 16px 16px 0 0;
    z-index: 2000;
  }
}

.horizonal-cards-heading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px 0;
  h2 {
    padding: 0;
    margin: 0;
  }
  ion-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    ion-label {
      text-decoration: underline;
      text-underline-offset: 10%;
    }
  }
  ion-icon {
    font-size: 24px;
  }
}

// Lists

.large-img-list {
  ion-thumbnail {
    width: 25%;
    height: auto;
    --border-radius: 15px;
  }
  ion-label {
    h5 {
      font-weight: 600;
    }
    ion-note {
      font-size: 10px;
      font-weight: bold;
    }
    ion-text p {
      font-size: 12px;
    }
  }
}
.small-img-list {
  ion-thumbnail {
    width: 15%;
    height: auto;
    --border-radius: 10px;
  }
  ion-label {
    h5 {
      font-weight: 600;
    }
    ion-note {
      font-size: 10px;
      font-weight: bold;
    }
    ion-text p {
      font-size: 12px;
    }
  }
}
// Alert message
ion-alert.alert-message {
  .alert-wrapper {
    background: var(--ion-background-color);
    //background: var(--ion-toolbar-background);
  }
}

ion-alert.alert-error {
  .alert-wrapper {
    background: var(--ion-background-color);
  }
}

//Modal
ion-modal {
  --background: var(--ion-background-color);
  --color: var(--ion-text-color);
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none !important;
  overflow: hidden !important;
}

ion-toolbar {
  --background: var(--ion-toolbar-background);
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

// Button Section
.btn-section {
  height: 8vh;
  width: 100%;
  position: fixed;
  padding: 0.5vh 2vw;
  background-color: var(--ion-background-color);
  z-index: 1001;
  display: flex;
  gap: 2vw;
  bottom: 0;
}

// Base Button
.btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  width: 100%;
  height: 6vh;
  border-radius: 2rem;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.btn-secondary {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);
}

.btn-danger {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.btn-dark {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
}

ion-fab-button {
  --background: rgba(var(--ion-background-color-rgb), 0.2);
  img {
    cursor: pointer;
    width: 100%;
  }
}

ion-fab-button.fab-button-close-active {
  //--color: var(--ion-color-danger);
  --background: var(--ion-color-danger);
}

ion-fab-list ion-fab-button {
  --background: var(--ion-color-primary);
  ion-icon {
    color: var(--ion-color-warning-contrast) !important;
    font-size: 24px !important;
  }

}

ion-grid {
  padding-right: 0;
  padding-left: 0;
}
