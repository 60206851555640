// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-text-color: #393d39;
  --ion-text-color-rgb: 57, 61, 57;

  --ion-toolbar-background: #ffffff;
  --ion-tab-bar-background: #ffffff;
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-item-background: #ffffff;
  --ion-card-background: #ffffff;

  /** primary **/
  --ion-color-primary: #f9b418;
  --ion-color-primary-rgb: 255, 196, 9;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e0a216;
  --ion-color-primary-tint: #FCD98B;
  --ion-color-primary-tint-rgb: 252, 217, 139;

  /** secondary **/
  --ion-color-secondary: #fa8668;
  --ion-color-secondary-rgb: 250, 134, 104;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e1785d;
  --ion-color-secondary-tint: #fa9277;

  /** tertiary **/
  --ion-color-tertiary: #88c28b;
  --ion-color-tertiary-rgb: 136, 194, 139;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #7aae7d;
  --ion-color-tertiary-tint: #93c896;

  /** success **/
  --ion-color-success: #bbe2bd;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #96b597;
  --ion-color-success-tint: #c9e8ca;

  /** warning **/
  --ion-color-warning: #f48568;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e1795e;
  --ion-color-warning-tint: #fb9e86;

  /** danger **/
  --ion-color-danger: #e26037;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cb5632;
  --ion-color-danger-tint: #e5704b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* Set the font family of the entire app */
  --ion-font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif;
}

// For automatically matching the device settings
// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-item-background: #121212;
  --ion-toolbar-background: #121212;
  --ion-tab-bar-background: #121212;
  --ion-card-background: #121212;

  --ion-color-primary-contrast: #121212;
  --ion-color-secondary-contrast: #121212;
  --ion-color-tertiary-contrast: #121212;
  // --ion-color-danger-contrast: #121212;
  --ion-color-medium-contrast: #121212;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  // --ion-color-step-50: #0d0d0d;
  // --ion-color-step-100: #1a1a1a;
  // --ion-color-step-150: #262626;
  // --ion-color-step-200: #333333;
  // --ion-color-step-250: #404040;
  // --ion-color-step-300: #4d4d4d;
  // --ion-color-step-350: #595959;
  // --ion-color-step-400: #666666;
  // --ion-color-step-450: #737373;
  // --ion-color-step-500: #808080;
  // --ion-color-step-550: #8c8c8c;
  // --ion-color-step-600: #999999;
  // --ion-color-step-650: #a6a6a6;
  // --ion-color-step-700: #b3b3b3;
  // --ion-color-step-750: #bfbfbf;
  // --ion-color-step-800: #cccccc;
  // --ion-color-step-850: #d9d9d9;
  // --ion-color-step-900: #e6e6e6;
  // --ion-color-step-950: #f2f2f2;
}

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 0, 0, 0;

//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     --ion-item-background: #ffffff;

//     --ion-card-background: #ffffff;
//   }

//   .ios ion-modal {
//     --ion-background-color: var(--ion-color-step-100);
//     --ion-toolbar-background: var(--ion-color-step-150);
//     --ion-toolbar-border-color: var(--ion-color-step-250);
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --ion-background-color: #121212;
//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;

//     --ion-item-background: #1e1e1e;

//     --ion-toolbar-background: #1f1f1f;

//     --ion-tab-bar-background: #1f1f1f;

//     --ion-card-background: #1e1e1e;
//   }
// }

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

// Ion segment button
ion-segment-button {
  --indicator-color: transparent;
  --indicator-color-checked: transparent;
  --background-checked: none;
  --background-hover: none;
  --background-focused: none;
}
